import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

// Load Page Contents
import contentEn from '../../content/pages/investor/about.en.yaml'
import contentId from '../../content/pages/investor/about.id.yaml'
import Hero from '../components/hero'
import { useUiKit } from '../hooks/uikit'
import SEO from '../components/seo'

export const query = graphql`
  query AboutInMainPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    santiPhoto: file(relativePath: { eq: "images/about/p-santi.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    farizPhoto: file(relativePath: { eq: "images/about/p-fariz.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    irwanPhoto: file(relativePath: { eq: "images/about/p-irwan.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    yentoroPhoto: file(relativePath: { eq: "images/about/p-yentoro.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    samsulPhoto: file(relativePath: { eq: "images/about/p-samsul.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    sriPhoto: file(relativePath: { eq: "images/about/p-sri.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    davidPhoto: file(relativePath: { eq: "images/about/p-david.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 70)
      }
    }
    structureOrganizationId: file(relativePath: { eq: "images/about/structure-organization-id.svg" }) {
      id
      publicURL
    }
    structureOrganizationEn: file(relativePath: { eq: "images/about/structure-organization-en.svg" }) {
      id
      publicURL
    }
  }
`

interface AboutPageProps extends PageProps {
  data: {
    santiPhoto: IGatsbyImageData
    farizPhoto: IGatsbyImageData
    irwanPhoto: IGatsbyImageData
    yentoroPhoto: IGatsbyImageData
    samsulPhoto: IGatsbyImageData
    sriPhoto: IGatsbyImageData
    davidPhoto: IGatsbyImageData
    structureOrganizationId: PublicUrlType
    structureOrganizationEn: PublicUrlType
  }
}

const AboutPage = (props: AboutPageProps) => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const [currentTab, setCurrentTab] = useState('#history')
  const uikit = useUiKit()

  const { structureOrganizationEn, structureOrganizationId } = props.data

  const santiPhoto = getImage(props.data.santiPhoto)
  const farizPhoto = getImage(props.data.farizPhoto)
  const irwanPhoto = getImage(props.data.irwanPhoto)
  const yentoroPhoto = getImage(props.data.yentoroPhoto)
  const samsulPhoto = getImage(props.data.samsulPhoto)
  const sriPhoto = getImage(props.data.sriPhoto)
  const davidPhoto = getImage(props.data.davidPhoto)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  useEffect(() => {
    switch (props.location.hash) {
      case '#history':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(0)
        break
      case '#vision-and-mission':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(1)
        break
      case '#organization-structure':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(2)
        break
      case '#board-of-comissioners':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(3)
        break
      case '#board-of-directors':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(4)
        break
      case '#supporting-institution':
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(5)
        break
      default:
        uikit.switcher('#aboutContents', { animation: 'uk-animation-fade' }).show(0)
        break
    }
    setCurrentTab(props.location.hash)
  }, [props.location.hash])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      <Hero>
        <h1 className="uk-heading-small">{content.title}</h1>
        <p>{content.description}</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <aside className="uk-width-1-3@m">
              <div className="uk-width-1-1 uk-visible@m">
                <ul className="uk-tab-left" uk-tab="connect: #aboutContents; animation: uk-animation-fade">
                  <li className={currentTab === '#history' ? 'uk-active' : undefined}>
                    <a href="#history" target="_blank" rel="noopener noreferrer">
                      {content.history.title}
                    </a>
                  </li>
                  <li className={currentTab === '#vision-and-mission' ? 'uk-active' : undefined}>
                    <a href="#vision-and-mission" target="_blank" rel="noopener noreferrer">
                      {content.vision_and_mission.title}
                    </a>
                  </li>
                  <li className={currentTab === '#organization-structure' ? 'uk-active' : undefined}>
                    <a href="#organization-structure" target="_blank" rel="noopener noreferrer">
                      {content.organization_structure.title}
                    </a>
                  </li>
                  <li className={currentTab === '#board-of-comissioners' ? 'uk-active' : undefined}>
                    <a href="#board-of-comissioners" target="_blank" rel="noopener noreferrer">
                      {content.board_of_comissioners.title}
                    </a>
                  </li>
                  <li className={currentTab === '#board-of-directors' ? 'uk-active' : undefined}>
                    <a href="#board-of-directors" target="_blank" rel="noopener noreferrer">
                      {content.board_of_directors.title}
                    </a>
                  </li>
                  <li className={currentTab === '#supporting-institution' ? 'uk-active' : undefined}>
                    <a href="#supporting-institution" target="_blank" rel="noopener noreferrer">
                      {content.supporting_institution.title}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="uk-hidden@m">
                <nav className="uk-navbar-transparent" uk-navbar="mode: click">
                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                      <li className="uk-active">
                        <a href="#">Menu</a>
                        <div className="uk-navbar-dropdown">
                          <ul
                            className="uk-nav uk-navbar-dropdown-nav"
                            uk-switcher="connect: #aboutContents; animation: uk-animation-fade"
                          >
                            <li className={currentTab === '#history' ? 'uk-active' : undefined}>
                              <a href="#history" target="_blank" rel="noopener noreferrer">
                                {content.history.title}
                              </a>
                            </li>
                            <li className={currentTab === '#vision-and-mission' ? 'uk-active' : undefined}>
                              <a href="#vision-and-mission" target="_blank" rel="noopener noreferrer">
                                {content.vision_and_mission.title}
                              </a>
                            </li>
                            <li className={currentTab === '#organization-structure' ? 'uk-active' : undefined}>
                              <a href="#organization-structure" target="_blank" rel="noopener noreferrer">
                                {content.organization_structure.title}
                              </a>
                            </li>
                            <li className={currentTab === '#board-of-comissioners' ? 'uk-active' : undefined}>
                              <a href="#board-of-comissioners" target="_blank" rel="noopener noreferrer">
                                {content.board_of_comissioners.title}
                              </a>
                            </li>
                            <li className={currentTab === '#board-of-directors' ? 'uk-active' : undefined}>
                              <a href="#board-of-directors" target="_blank" rel="noopener noreferrer">
                                {content.board_of_directors.title}
                              </a>
                            </li>
                            <li className={currentTab === '#supporting-institution' ? 'uk-active' : undefined}>
                              <a href="#supporting-institution" target="_blank" rel="noopener noreferrer">
                                {content.supporting_institution.title}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </aside>

            <div className="uk-width-expand@m">
              <ul id="aboutContents" className="uk-switcher">
                <li>
                  <article>
                    <h1 id="#history" className="uk-h2">
                      {content.history.title}
                    </h1>
                    <div dangerouslySetInnerHTML={{ __html: content.history.content }}></div>
                  </article>
                </li>
                <li>
                  <article>
                    <h1 id="#vision-and-mission" className="uk-h2">
                      {content.vision_and_mission.title}
                    </h1>
                    {content.vision_and_mission.content.map(({ title, content }: any) => (
                      <>
                        <h2 className="uk-h3">{title}</h2>
                        <p>{content}</p>
                      </>
                    ))}
                  </article>
                </li>
                <li>
                  <article>
                    <h1 id="#organization-structure" className="uk-h2">
                      {content.organization_structure.title}
                    </h1>
                    <img
                      src={
                        i18n.language === 'en' ? structureOrganizationEn.publicURL : structureOrganizationId.publicURL
                      }
                    />
                  </article>
                </li>
                <li>
                  <article>
                    <h1 id="#board-of-comissioners" className="uk-h2">
                      {content.board_of_comissioners.title}
                    </h1>

                    <h2 className="uk-h3">{content.board_of_comissioners.content.fariz.name}</h2>
                    <div>
                      {farizPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={farizPhoto}
                          alt={content.board_of_comissioners.content.fariz.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_comissioners.content.fariz.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_comissioners.content.fariz.description }}
                      ></div>
                    </div>
                    <hr />
                    
                    <h2 className="uk-h3">{content.board_of_comissioners.content.irwan.name}</h2>
                    <div>
                      {irwanPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={irwanPhoto}
                          alt={content.board_of_comissioners.content.irwan.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_comissioners.content.irwan.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_comissioners.content.irwan.description }}
                      ></div>
                    </div>
                    <hr />

                    <h2 className="uk-h3">{content.board_of_comissioners.content.santi.name}</h2>
                    <div>
                      {santiPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={santiPhoto}
                          alt={content.board_of_comissioners.content.santi.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_comissioners.content.santi.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_comissioners.content.santi.description }}
                      ></div>
                    </div>
                  </article>
                </li>
                <li>
                  <article>
                    <h1 id="#board-of-directors" className="uk-h2">
                      {content.board_of_directors.title}
                    </h1>

                    <h2 className="uk-h3">{content.board_of_directors.content.yentoro.name}</h2>
                    <div>
                      {yentoroPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={yentoroPhoto}
                          alt={content.board_of_directors.content.yentoro.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_directors.content.yentoro.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_directors.content.yentoro.description }}
                      ></div>
                    </div>
                    <hr />

                    <h2 className="uk-h3">{content.board_of_directors.content.sri.name}</h2>
                    <div>
                      {sriPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={sriPhoto}
                          alt={content.board_of_directors.content.sri.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_directors.content.sri.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_directors.content.sri.description }}
                      ></div>
                    </div>
                    <hr />

                    <h2 className="uk-h3">{content.board_of_directors.content.samsul.name}</h2>
                    <div>
                      {samsulPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={samsulPhoto}
                          alt={content.board_of_directors.content.samsul.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_directors.content.samsul.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_directors.content.samsul.description }}
                      ></div>
                      <div style={{ height: '65px' }}></div>
                    </div>
                    <hr />

                    <h2 className="uk-h3">{content.board_of_directors.content.david.name}</h2>
                    <div>
                      {davidPhoto && (
                        <GatsbyImage
                          className="uk-align-left"
                          image={davidPhoto}
                          alt={content.board_of_directors.content.david.name}
                          style={{ width: '200px', height: '200px', borderRadius: '5px' }}
                        />
                      )}
                      <h3 className="uk-h4">{content.board_of_directors.content.david.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: content.board_of_directors.content.david.description }}
                      ></div>
                    </div>
                  </article>
                </li>
                <li>
                  <article>
                    <h1 id="#supporting-institution" className="uk-h2">
                      {content.supporting_institution.title}
                    </h1>
                    {content.supporting_institution.content.map(({ name, title, place }: any) => (
                      <>
                        <h2 className="uk-h3">{title}</h2>
                        <dl>
                          <dt>{name}</dt>
                          <dd>{place}</dd>
                        </dl>
                        <hr />
                      </>
                    ))}
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutPage
